import { useQuery } from 'react-query'
import { useState, useEffect } from 'react'
import { IsComputeUrlValid, axios } from 'utils'
import {
  TFocusGroup,
  TPlotSeries,
  TCostHistoryQueryResponse
} from 'services/costhistory.interface'

import {
  GraphCardWrapper,
  GraphCardGroup,
  ChartButtonGroup,
  GraphCard,
  TTableData,
  BarLineChart
} from 'components'
import {
  TReportBodyData,
  TReportFilterDisplay
} from 'services/report.interface'
import { TQueryRequest } from 'services/commons.interface'
import {
  convertReportFilterDisplayToQuery,
  convertTFocusGroupToBarChartSeries,
  convertTFocusGroupToBarChartSeriesKey,
  // convertTFocusGroupToTPlotSeries
} from 'utils/dataConverter'
import { AreaChartSeries } from '@mantine/charts'
import { Bounce, toast } from 'react-toastify'
import { TCurrency } from 'services/cost.interface'
import FocusTableCard from './FocusTableCard'
import FilterEditor from './mutation-new-report-modal/FilterEditor'

interface ReportViewerProps {
  reportParam?: TReportFilterDisplay
  readonlyParam?: boolean
  nameParam: string
  descriptionParam: string
  currency: TCurrency
  onChanged?: (payload: TReportBodyData) => void
}
function ReportEditor({
  readonlyParam,
  reportParam,
  nameParam,
  descriptionParam,
  currency,
  onChanged
}: ReportViewerProps) {
  // ************************************
  // Input Parameters query, value, chart
  // ************************************
  const [chartType, setChartType] = useState<'line' | 'area' | 'bar'>('line')
  const [focusField, setFocusField] = useState<string>('totalUsageQuantity')
  const [queryRequest, setQueryRequest] = useState<TQueryRequest>()
  const [name, setName] = useState<string>(nameParam)
  const [description, setDescription] = useState<string>(descriptionParam)
  const [reportFilterDisplay, setReportFilterDisplay] =
    useState<TReportFilterDisplay>() // Used to trigger query of filters
  // store the payload that is used to save to api
  const [payload, setPayload] = useState<TReportBodyData>()
  const [queryKey, setQueryKey] = useState<string>('') // Used to trigger query of filters
  // ******************************************
  // Data formatter to fit the required display
  // ******************************************
  const [focusData, setFocusData] = useState<TFocusGroup[]>([])
  const [seriesData, setSeriesData] = useState<TPlotSeries[] | object[]>([])
  const [seriesDataKey, setSeriesDataKey] = useState<AreaChartSeries[]>([])
  const [tableData, setTableData] = useState<TTableData[]>([])

  // *************************************
  // Flag that the data has been converted
  // *************************************
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)

  // ******************************
  // Setup the states on first load
  // ******************************
  useEffect(() => {
    if (!reportParam) return
    setReportFilterDisplay(reportParam)
    setChartType(reportParam?.chart?.chartType || 'line')
    setFocusField(reportParam?.chart?.chartValue || 'totalUsageQuantity')
    setQueryRequest(convertReportFilterDisplayToQuery(reportParam))
  }, [reportParam])

  useEffect(() => {
    setName(nameParam)
    setDescription(descriptionParam)
  }, [nameParam, descriptionParam])

  // ***********************
  // Update the query string
  // ***********************
  useEffect(() => {
    if (onChanged && payload) {
      onChanged(payload)
    }
  }, [payload])

  // ****************************
  // This will trigger the filter
  // ****************************
  useEffect(() => {
    if (queryRequest?.name) {
      setQueryKey(JSON.stringify(queryRequest))
    }
  }, [queryRequest])

  // *****************************************
  // Effect to convert data to focus and table
  // *****************************************
  useEffect(() => {
    // ************************************
    // Check if data is array and has items
    // ************************************
    if (!Array.isArray(focusData) || focusData.length === 0) {
      // **************
      // Clear the data
      // **************
      setTableData([])
      setSeriesData([])
      setDataProcessed(true)
      return
    }
    // ***************************************
    // Convert the focus data for a table view
    // ***************************************
    const tableData: TTableData[] = []
    focusData?.forEach((key) => {
      Object.entries(key).forEach(([, value]) => {
        tableData.push(value)
      })
    })
    // ********************************
    // Convert the focus data for chart
    // ********************************
    /* const serise = convertTFocusGroupToTPlotSeries(
      focusData,
      'timestamp',
      focusField
    ) */
    const barSeries = convertTFocusGroupToBarChartSeries(
      focusData,
      'timestamp',
      focusField
    )
    const barSeriesKey = convertTFocusGroupToBarChartSeriesKey(
      focusData
    )
    setTableData(tableData)
    setSeriesData(barSeries)
    setSeriesDataKey(barSeriesKey)
    setDataProcessed(true)
  }, [focusData, focusField])

  // *********************************
  // Handle if the filters are changed
  // *********************************
  const handleMutatedReportFilter = (payload: TReportBodyData) => {
    setPayload(payload)
  }

  // **************************
  // Load the data from the API
  // **************************

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async ({ queryKey }) => {
      try {
        if (IsComputeUrlValid(queryKey[0])) {
          setDataProcessed(false)

          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setFocusData(res.data.data)
            })
            .catch((error) => {
              toast.error(`Error code ${error.status}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
              // throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  if (error) return <div>An error has occurred</div>
  return (
    <GraphCardWrapper smPadding>
      <GraphCardGroup compact spacing={1}>
        <ChartButtonGroup
          selectedButton={chartType}
          onClick={(chart) => setChartType(chart)}
        />
      </GraphCardGroup>
      {!readonlyParam && (
        <GraphCardGroup grow compact spacing={1}>
          <GraphCard isOutline={false} spacing={5}>
            <GraphCard isOutline={false} spacing={5}>
              <FilterEditor
                nameParam={name}
                descriptionParam={description}
                chartTypeParam={chartType}
                reportParam={reportFilterDisplay}
                onMutated={handleMutatedReportFilter}
                onFilter={(query, field) => {
                  setFocusField(field)
                  if (!query?.name) {
                    toast.error('Name required', {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: 'colored',
                      transition: Bounce,
                    })
                  }
                  setQueryRequest(query)
                }}
              />
            </GraphCard>
          </GraphCard>
          <GraphCard>
            <BarLineChart
              isLoading={!dataProcessed}
              data={seriesData}
              dataSeries={seriesDataKey}
              chartType={chartType}
              currency={currency}
            />
          </GraphCard>
        </GraphCardGroup>
      )}
      {readonlyParam && (
        <GraphCardGroup compact spacing={1}>
          <GraphCard>
            <BarLineChart
              isLoading={!dataProcessed}
              data={seriesData}
              dataSeries={seriesDataKey}
              chartType={chartType}
              currency={currency}
            />
          </GraphCard>
        </GraphCardGroup>
      )}
      {!readonlyParam && (
        <GraphCardGroup spacing={20}>
          <FocusTableCard data={tableData} currency={currency} isLoading={!dataProcessed} />
        </GraphCardGroup>
      )}
    </GraphCardWrapper>
  )
}

export default ReportEditor
