import { axios } from 'utils'
import {
  // GraphCardContent,
  GraphCardGroup,
  SpinnerLoader,
} from 'components'
import { v4 as uuid } from 'uuid'
import LabeledDataComponent from 'components/LabeledDataRepresenation'
import { useEffect, useState } from 'react'
import 'chart.js/auto';
// import { Bar } from 'react-chartjs-2'
import { useQuery } from 'react-query'
// import autocolors from 'chartjs-plugin-autocolors';
import { useElementSize } from '@mantine/hooks'
import useAuthentication from 'hooks/useAuthentication'
// import autocolors from 'chartjs-plugin-autocolors';
import { TCostHistoryQueryResponse, TFocusGroup, newTFocusGroup } from 'services/costhistory.interface'
import { TPieChartData } from 'services/chart.interface'
import { useTour } from '@reactour/tour'
// import autocolors from 'chartjs-plugin-autocolors';
import {
  Cell,
  // LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer
} from 'recharts'
import useProject from 'hooks/useProject'
import { Button } from '@mantine/core'
import { Bounce, toast } from 'react-toastify'
import DateRangePickerComponent from './dateRangePicker'
import { parseAccumulatedFalseData, parseAccumulatedTrueData, renderLabel, renderLegend } from './chartHelperFunctions'
import {
  createWidgetAggregatedAPIFieldCall,
  createWidgetAggregatedAPILabelCall,
  createWidgetAPIFieldCall,
  createWidgetAPILabelCall,
  dataNameAndColor,
  projectAndTheirApps,
  query
} from '../project-widget/widgetHelpFunctions'
import { tourConfig } from './tour'
import { WidgetData } from '../project-widget/widgetStatev2'
import { TutorialContainer } from '../project-tutorial'
import { getWidgetData, setWidgetData } from '../project-widget/widgetDataCaching'

// import { tourConfig } from './tour'
export type chartJsDataSets = { label: string, data: number[] }
export type chartJsData = { labels: string[], datasets: chartJsDataSets[] }

interface LabeledDataCardProps {
  id: string,
  labels: string[],
  fields: string[],
  initialType: string,
  editCallBack: () => void,
  closeCallBack: () => void,
  deleteWidget: () => void,
  widgetObject: WidgetData,
  setErrorMessage: (msg: string) => void
  deleteErrorMessage: () => void
}

export function LabeledDataCard({
  id,
  labels,
  fields,
  initialType,
  editCallBack,
  closeCallBack,
  deleteWidget,
  setErrorMessage,
  deleteErrorMessage,
  widgetObject
}: LabeledDataCardProps) {
  const {
    fetchProjectsStatus,
  } = useProject()
  widgetObject.resetOptions(id)
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)

  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }
  const { currency } = useAuthentication()
  const [label, setLabel] = useState<string>(widgetObject.getLabel(id) === '' && labels.length !== 0 ? fields[0] : widgetObject.getLabel(id))
  const [toTutorial, setTutorial] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[0]))
  const [workStartDate, setWorkStartDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[0]))
  const { setIsOpen, setSteps, setCurrentStep } = useTour()

  const [amountOfMonths, setAmountOfMonths] = useState<number>(30)
  useEffect(() => {
    if (widgetObject.getWindow(id) === 'lastmonth') {
      startDate.setDate(startDate.getDate() - amountOfMonths)
      workStartDate.setDate(workStartDate.getDate() - amountOfMonths)
    }
    setAmountOfMonths(0)
  })
  const [endDate, setEndDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[1]))
  const [workEndDate, setWorkEndDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[1]))

  const [window, setWindow] = useState<string>(widgetObject.getWindow(id))
  const [dateChanged, setDateChanged] = useState<boolean>(false)
  const [type, setType] = useState<string>(widgetObject.getType(id) === '' ? initialType : widgetObject.getType(id))

  const [stepDuration] = useState<string>(widgetObject.getStepDuration(id))
  const [loading, setLoading] = useState<boolean>(false)
  const [chartDataValues, setChartDataValues] = useState<projectAndTheirApps[]>([])
  const [chartDataNames, setChartDataNames] = useState<dataNameAndColor[]>([])
  const [accumulatedChartData, setAccumulatedChartData] = useState<TPieChartData[]>([])
  const [providers, setProviders] = useState<string[]>([])
  const [provider, setProvider] = useState<string>(widgetObject.getProvider(id))
  const [tempProvider, setTempProvider] = useState<string>(widgetObject.getProvider(id))
  const [edit, setEdit] = useState<boolean>(false)
  // const setEditToTrue = () => {
  //   setEdit(true)
  // }
  const setEditToFalse = () => {
    setEdit(false)
  }

  const {
    ref,
    width,
    height
  } = useElementSize();

  function fieldToQuery(field: string) {
    const lst = field.split(' ')
    let res = ''
    lst.forEach((el) => {
      if (res === '') {
        res = el.toLowerCase()
      } else {
        res = `${res}${el[0].toUpperCase() + el.slice(1)}`
      }
    })
    return res
  }
  function getQuery(): query {
    if (type === 'accumulateFalseChart') {
      if (labels.findIndex((el) => el === label) !== -1) {
        return createWidgetAPILabelCall(
          label,
          window,
          stepDuration,
          provider
        )
      }
      return createWidgetAPIFieldCall(
        fieldToQuery(label),
        window,
        stepDuration,
        provider
      )
    }
    if (labels.findIndex((el) => el === label) !== -1) {
      return createWidgetAggregatedAPILabelCall(
        label,
        provider
      )
    }
    return createWidgetAggregatedAPIFieldCall(
      fieldToQuery(label),
      provider
    )
  }

  const [queryRequest, setQueryRequest] = useState<query>(getQuery())
  const [queryKey, setQueryKey] = useState<string>(`initial${id}`)
  // Used to trigger query of filters
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [path, setPath] = useState<string[]>(widgetObject.getChartPath(id))
  const [legendUpdate, setLegendUpdate] = useState<boolean>(false)
  // const [edit, setEdit] = useState<boolean>(false)
  function getSessTag() {
    const theLabel = widgetObject.getLabel(id)
    const theWindow = widgetObject.getWindow(id)
    if (type === 'accumulateTrueChart') {
      return `labelWidget${theLabel}`
    } return `labelWidget${theLabel}${theWindow}`
  }
  const [focusData, setFocusData] = useState<TFocusGroup[] | newTFocusGroup[]>(
    getWidgetData(getSessTag())
  )
  const [sessionStorageTag, setSessionStorageTag] = useState<string>(
    getSessTag()
  )

  const changeToAccumulateFalseChart = () => {
    if (type !== 'accumulateFalseChart') {
      setType('accumulateFalseChart')
      widgetObject.setType(id, 'accumulateFalseChart')
      setFocusData([])
      setPath([])
    }
  }

  const changeToAccumulateTrueChart = () => {
    if (type !== 'accumulateTrueChart') {
      setType('accumulateTrueChart')
      widgetObject.setType(id, 'accumulateTrueChart')
      setFocusData([])
      setPath([])
    }
  }
  const tutorialChangeToAccumulateFalseChart = () => {
    setType('accumulateFalseChart')
    widgetObject.setType(id, 'accumulateFalseChart')
    setFocusData([])
    setPath([])
  }

  const tutorialChangeToAccumulateTrueChart = () => {
    setType('accumulateTrueChart')
    widgetObject.setType(id, 'accumulateTrueChart')
    setFocusData([])
    setPath([])
  }
  const reloadFunctionality = () => {
    setDataProcessed(false)
    setLoading(false)
    setQueryKey(uuid())
  }
  useEffect(() => {
    if (fetchProjectsStatus.isLoading) reloadFunctionality()
  }, [fetchProjectsStatus])

  // ***************************************************************************
  // Add the Edit widget to the options of this widget.
  // ***************************************************************************

  widgetObject.editOptions(id, 'Add date range', changeToAccumulateFalseChart)
  widgetObject.editOptions(id, 'Accumulate', changeToAccumulateTrueChart)
  widgetObject.editOptions(id, 'Help', () => {
    setCurrentStep(0)
    setIsOpen(true)
    setTutorial(false)
  })
  // widgetObject.editOptions(id, 'Edit widget', () => {
  //   setEditToTrue()
  // })
  widgetObject.editOptions(id, 'Reload widget', () => {
    reloadFunctionality()
  })
  widgetObject.editOptions(id, 'Delete widget', () => {
    sessionStorage.removeItem(sessionStorageTag)
    deleteWidget()
  })

  useEffect(() => {
    setQueryRequest(getQuery())
    setPath([])
    const key = JSON.stringify(uuid())
    setQueryKey(key)
    setDataProcessed(false)
    setLoading(false)
  }, [type, label, window, provider])

  // useEffect(() => {
  //   if (!dataProcessed) {
  //     setQueryRequest(getQuery())
  //     const testQuery = JSON.stringify(queryRequest)
  //     if (testQuery === queryKey) {
  //       setDataProcessed(true)
  //     } else {
  //       setQueryKey(JSON.stringify(getQuery()))
  //     }
  //   }
  // })

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!(queryKey === `initial${id}` && focusData.length !== 0) && !dataProcessed) {
          setDataProcessed(true)
          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setFocusData(res.data.data)
              if (type === 'accumulateTrueChart') {
                setWidgetData(sessionStorageTag, `labelWidget${label}`, res.data.data)
                setSessionStorageTag(`labelWidget${label}${provider}`)
              } else {
                setWidgetData(sessionStorageTag, `labelWidget${label}${window}`, res.data.data)
                setSessionStorageTag(`labelWidget${label}${window}`)
              }
              deleteErrorMessage()
              setLoading(true)
              if (toTutorial) {
                if (type === 'accumulateFalseChart') {
                  if (setSteps) {
                    setSteps(
                      tourConfig(
                        id,
                        type,
                        [
                          editCallBack,
                          tutorialChangeToAccumulateTrueChart,
                          closeCallBack,
                          tutorialChangeToAccumulateFalseChart
                        ]
                      )
                    )
                  }
                } else if (setSteps) {
                  setSteps(
                    tourConfig(
                      id,
                      type,
                      [
                        editCallBack,
                        tutorialChangeToAccumulateFalseChart,
                        closeCallBack,
                        tutorialChangeToAccumulateTrueChart
                      ]
                    )
                  )
                }
                setIsOpen(true)
                setTutorial(false)
              }
              // editData(id, res.data.data)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setErrorMessage('There is no data to be found for this configuration.')
                setFocusData([])
                setLoading(true)
                setDataProcessed(true)
              }
            })
        } else if ((queryKey === `initial${id}` && focusData.length !== 0)) {
          setLoading(true)
          setDataProcessed(true)
        }
        if (providers.length === 0) {
          await axios
            .get('/fields/provider')
            .then((res) => {
              setProviders(res.data.concat(''))
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('There are no providers to be found.', {
                  // position: 'top-right',
                  className: 'relative',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    if (type === 'accumulateFalseChart') {
      const res = parseAccumulatedFalseData(focusData as TFocusGroup[])
      setChartDataValues(res.values)
      setChartDataNames(res.names)
      // setLoading(true)
    } else {
      const res = parseAccumulatedTrueData(focusData as newTFocusGroup[], path)
      setAccumulatedChartData(res.sort((a, b) => b.value - a.value))
      // if (providers.length <= 1) {
      //   setProviders(getProviderNames(focusData as newTFocusGroup[]))
      // }
      // setLoading(true)
    }
    setLegendUpdate(true)
  }, [focusData, path, provider])

  useEffect(() => {
    const window = `${startDate?.toISOString().replaceAll('.000Z', 'Z')},${endDate?.toISOString().replaceAll('.000Z', 'Z')}`
    setWindow(window)
    widgetObject.editWindow(id, window)
  }, [startDate, endDate])
  // function fieldFilter(el){
  //   el !=== 'monthly cost'
  // }
  function getTour() {
    if (type === 'accumulateFalseChart') {
      return tourConfig(
        id,
        type,
        [
          editCallBack,
          tutorialChangeToAccumulateTrueChart,
          closeCallBack,
          tutorialChangeToAccumulateFalseChart,
          changeTutorialUpdater
        ]
      )
    }
    return tourConfig(
      id,
      type,
      [
        editCallBack,
        tutorialChangeToAccumulateFalseChart,
        closeCallBack,
        tutorialChangeToAccumulateTrueChart,
        changeTutorialUpdater
      ]
    )
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full" ref={ref}>

      <TutorialContainer
        tag="CumulativeWidget"
        updater={tutorialUpdater}
        tourConfig={
          getTour()
        }
      >
        <div>
          {!edit && (
            <GraphCardGroup className="h-full">
              <div
                data-tour={`LabeledDataChart${id}`}
                className="block h-full w-full"
              >
                <SpinnerLoader isLoading={!loading}>
                  <div
                    className="py-2 flex flex-row h-fit place-items-center"
                  >
                    <span className="pr-2 flex text-gray-600 font-semibold w-1/6">Choose a provider:</span>
                    <select
                      className="py-2 px-2 border border-gray-500 rounded-lg bg-white w-1/2"
                      value={provider}
                      name="Labels"
                      id={`providerSelectForLabeledDataChart${id}`}
                      onChange={(newProvider) => {
                        setProvider(newProvider.target.value)
                        widgetObject.setProvider(id, newProvider.target.value)
                        // setPath([])
                      }}
                    >
                      {providers.map((el) => {
                        return (
                          <option
                            key={`SelectionOptionOf${id}FromLabelsWithValue${el}`}
                            className="bg-white"
                          >
                            {el}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div
                    className="py-2 flex flex-row h-fit place-items-center"
                  >
                    <span className="pr-2 flex text-gray-600 font-semibold w-1/6">
                      Group cost by:
                    </span>
                    <select
                      className="py-2 px-2 border border-gray-500 rounded-lg bg-white w-1/2"
                      value={label}
                      name="Labels"
                      data-tour={`labelSelectForLabeledDataChart${id}`}
                      id={`labelSelectForLabeledDataChart${id}`}
                      onChange={(newLabel) => {
                        setLabel(newLabel.target.value)
                        // setProviders([])
                        widgetObject.editLabel(id, newLabel.target.value)
                        // setPath([])
                      }}
                    >
                      <optgroup label="Labels">
                        {labels.map((el) => {
                          return (
                            <option
                              key={`SelectionOptionOf${id}FromLabelsWithValue${el}`}
                              className="bg-white"
                            >
                              {el}
                            </option>
                          )
                        })}
                      </optgroup>
                      <optgroup label="Fields">
                        {fields.filter((field) => {
                          const parts = field.split(' ')
                          const index = parts.findIndex((el) => {
                            return el.toLowerCase() === 'cost'
                          })
                          return (
                            index === -1
                            && field !== 'Updated flag'
                            && field !== 'Data date'
                          )
                        }).sort((a, b) => {
                          return a.localeCompare(b)
                        }).map((el) => {
                          return (
                            <option
                              key={`SelectionOptionOf${id}FromFieldsWithValue${el}`}
                              className="bg-white"
                            >
                              {el}
                            </option>
                          )
                        })}
                      </optgroup>
                    </select>
                  </div>

                  {type === 'accumulateFalseChart' && (
                    <div
                      className="flex flex-row py-2"
                      data-tour={`windowSelectForLabeledDataChart${id}`}
                    >
                      <span className="pr-2 flex text-gray-600 font-semibold w-1/6">
                        Date range:
                      </span>
                      <div className="w-1/2">
                        <DateRangePickerComponent
                          // size="lg"
                          startDate={workStartDate}
                          endDate={workEndDate}
                          onClosed={() => {
                            if (dateChanged) {
                              setDateChanged(false)
                              setStartDate(workStartDate)
                              setEndDate(workEndDate)
                            }
                          }}
                          handleChange={(event) => {
                            if (event[0].startDate) {
                              setWorkStartDate(event[0].startDate)
                              setDateChanged(true)
                              // editStartDate(id, event[0].startDate)
                            }
                            if (event[0].endDate) {
                              setWorkEndDate(event[0].endDate)
                              setDateChanged(true)
                              // editEndDate(id, event[0].endDate)
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {type === 'accumulateFalseChart' && (
                    <div
                      className=" h-7/8"
                      data-tour={`accumulateFalseChart${id}`}
                    >

                      <div style={{ display: 'flex', height: '75%' }}>
                        <LabeledDataComponent
                          currency={currency}
                          data={chartDataValues}
                          series={chartDataNames}
                          height={(height / 8) * 6}
                          width={width}
                        />
                      </div>
                    </div>
                  )}

                  {type === 'accumulateTrueChart' && (
                    <div style={{ height: (height * 4) / 5 }}>
                      {/* <div className="py-5">
                        <span className="font-semibold text-gray-600">Provider:
                         {provider !== '' ? provider : 'All'}</span>
                      </div> */}
                      <div
                        data-tour={`accumulateTrueChartPath${id}`}
                      >
                        {[''].concat(path).map((el, idx) => {
                          if (el !== '') {
                            return (
                              <span
                                key={`spanElementOfTheLabeledDataWidget${id}WithValue${el}`}
                                className="text-sm text-gray-500 font-semibold px-0 cursor-pointer hover:underline text-wrap"
                                onClick={() => {
                                  setPath(path.slice(0, idx))
                                  setLegendUpdate(false)
                                }}
                              >
                                {el}/
                              </span>
                            )
                          }
                          return (
                            <span
                              key={`spanElementOfTheLabeledDataWidget${id}WithValueHome`}
                              className="text-sm text-gray-500 font-semibold px-0 cursor-pointer hover:underline text-wrap"
                              onClick={() => {
                                setPath([])
                                setLegendUpdate(false)
                              }}
                            >
                              Home/
                            </span>
                          )
                        })}
                      </div>
                      <div
                        className="h-full"
                        data-tour={`accumulateTrueChart${id}`}
                      >
                        <ResponsiveContainer>
                          <PieChart>

                            <Pie
                              data={accumulatedChartData}
                              dataKey="value"
                              nameKey="name"
                              innerRadius={0}
                              labelLine={false}
                              cx="50%"
                              cy="50%"
                              fill="#82ca9d"
                              onClick={(event) => {
                                if (event) {
                                  const nextEl = event.name
                                  let isEnd = false
                                  let tempData = focusData[0] as newTFocusGroup
                                  path.forEach((pathPiece) => {
                                    if (Object.getOwnPropertyNames(
                                      tempData[pathPiece].children
                                    ).length
                                      !== 0) {
                                      tempData = tempData[pathPiece].children
                                    } else {
                                      isEnd = true
                                    }
                                  })
                                  if (!isEnd) {
                                    setPath(path.concat(nextEl))
                                    widgetObject.setChartPath(id, path.concat(nextEl))
                                    setLegendUpdate(false)
                                  }
                                }
                              }}
                              label={(value) => {
                                return renderLabel(value, currency, accumulatedChartData[0].value)
                              }}
                            >
                              {accumulatedChartData.map((entry) => (
                                <Cell key={entry.color} fill={entry.color} />
                              ))}
                            </Pie>
                            {legendUpdate && (
                              <Legend content={renderLegend(accumulatedChartData, id, currency)} height={36} layout="vertical" align="left" verticalAlign="top" />
                            )}
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  )}
                </SpinnerLoader>
              </div>
            </GraphCardGroup>
          )}
          {edit && (
            <div>
              <div className="absolute bottom-px w-full">
                <div className="justify-self-center py-4 flex place-content-center">
                  <div className="px-3">
                    <Button
                      className="bg-white hover:bg-gray-600 text-black hover:text-white border-2 border-black"
                      onClick={() => {
                        // setPath(getTableDataPath(id))
                        setTempProvider(provider)
                        setEditToFalse()
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="px-3">
                    <Button
                      className="bg-[#668957] hover:bg-[#93ac89] border-2 border-[#668957]"
                      onClick={() => {
                        setProvider(tempProvider)
                        widgetObject.setProvider(id, tempProvider)
                        // setPath(getTableDataPath(id))
                        setEditToFalse()
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </TutorialContainer>
    </div>
  )
}
