import {
  GraphCard,
  GraphCardGroup,
  GraphCardWrapper
} from 'components/graph-card'
import 'react-vis/dist/style.css'
import { TPlotSeries } from 'services/costhistory.interface'
import Spinner from 'components/spinner'
import { AreaChart, BarChart, AreaChartSeries, LineChart } from '@mantine/charts'
import { TCurrency } from 'services/cost.interface'
import { makeTooltip } from 'components/LabeledDataRepresenation/helper'

interface BarLineChartProps {
  data: TPlotSeries[] | object[]
  dataSeries: AreaChartSeries[]
  isLoading?: boolean
  chartType?: 'line' | 'bar' | 'area',
  currency: TCurrency
  // height?: number | undefined
}

function BarLineChart({
  data,
  dataSeries,
  chartType,
  isLoading,
  currency
}: // height
BarLineChartProps) {
  if (isLoading) {
    return (
      <GraphCardWrapper>
        <GraphCardGroup
          className="flex justify-center items-center h-full py-20"
          compact
          spacing={20}
        >
          <Spinner size={40} />
        </GraphCardGroup>
      </GraphCardWrapper>
    )
  }
  return (
    <div className="h-full">
      <GraphCard isOutline={false} spacing={5}>
        {chartType === 'bar' && (
          <BarChart
            h={300}
            data={data}
            dataKey="date"
            type="stacked"
            unit={currency.symbol}
            series={dataSeries}
            tooltipProps={{
              content: ({ label, payload }) => makeTooltip(label, payload, currency),
            }}
          />
        )}
        {chartType === 'line' && (
          <LineChart
            h={300}
            data={data}
            dataKey="date"
            curveType="linear"
            series={dataSeries}
            unit={currency.symbol}
            tooltipProps={{
              content: ({ label, payload }) => makeTooltip(label, payload, currency),
            }}
          />
        )}
        {chartType === 'area' && (
          <AreaChart
            h={300}
            data={data}
            dataKey="date"
            series={dataSeries}
            type="stacked"
            curveType="linear"
            unit={currency.symbol}
            tooltipProps={{
              content: ({ label, payload }) => makeTooltip(label, payload, currency),
            }}
          />
        )}
      </GraphCard>
    </div>
  )
}

export default BarLineChart
