import { CHART_COLORS } from 'constants/color.constants'
import { TPieChartData } from 'services/chart.interface'
import { newTFocusGroup, TFocus, TFocusGroup } from 'services/costhistory.interface'
import { TCurrency } from 'services/cost.interface'
import { projectAndTheirApps, dataNameAndColor } from '../project-widget/widgetHelpFunctions'
// import { chartJsDataSets } from '.'

export function dateFormatChanger(date: string) {
  return date.split('T')[0]
}

export function parseAccumulatedFalseData(
  receivedData: TFocusGroup[]
): { values: projectAndTheirApps[], names: dataNameAndColor[] } {
  if (receivedData.length !== 0) {
    let ctr = 0
    const newData: projectAndTheirApps[] = []
    const dataNames: dataNameAndColor[] = []
    receivedData.forEach((dataMoment) => {
      const labels = Object.getOwnPropertyNames(dataMoment)
      if (labels.length !== 0) {
        const { timestamp } = dataMoment[labels[0]]
        const index = newData.findIndex((el) => {
          return el.label === dateFormatChanger(timestamp.toString())
        });
        const temp = index !== -1 ? newData[index] : {
          label: dateFormatChanger(timestamp.toString())
        };
        let item: projectAndTheirApps = temp
        labels.forEach((label) => {
          const dataElement = dataMoment[label]
          // item = { ...item, [label]: (dataElement as any).total_billed_cost }
          item = { ...item, [label]: Number(dataElement.totalBilledCost).toFixed(2) }
          // needs to be changed to item = { ...item, [label]: dataElement.totalBilledCost }
          // when the data matches
          const possibleNameIndex = dataNames.findIndex((pair) => pair.name === label)
          if (possibleNameIndex === -1) {
            dataNames.push({ name: label, color: CHART_COLORS[ctr] })
            ctr++
          }
        })
        if (index !== -1) {
          newData[newData.findIndex((el) => {
            return el.label === dateFormatChanger(timestamp.toString())
          })] = item
        } else {
          newData.push(item)
        }
      }
    })
    newData.sort((a, b) => {
      if (a.timestamp < b.timestamp) {
        return -1;
      }
      if (a.timestamp > b.timestamp) {
        return 1;
      }
      return 0;
    });
    return { values: newData, names: dataNames }
  }
  return { values: [], names: [] }
}

export function parseAccumulatedTrueData(
  receivedData: newTFocusGroup[],
  path: string[]
): TPieChartData[] {
  if (receivedData.length !== 0) {
    let newData: TPieChartData[] = []
    let ctr = 0
    let resourceTempData: newTFocusGroup = receivedData[0]
    let resourceData: TFocus[] = []
    path.forEach((el) => {
      if (Object.getOwnPropertyNames(resourceTempData[el].children).length !== 0) {
        resourceTempData = resourceTempData[el].children
      } else resourceData = resourceTempData[el].resources
    })
    if (path.length === 0) {
      const labels = Object.getOwnPropertyNames(receivedData[0])
      newData = labels.map((name) => {
        const data = receivedData[0][name]
        const value = Number(data.monthlyCost.toFixed(2))
        const color = CHART_COLORS[ctr]
        if (ctr === CHART_COLORS.length - 1) ctr = 0
        else ctr++
        return { name, value, color }
      })
      return newData
    }
    return resourceData.map((data) => {
      const name = data.resourceId
      const value = Number(data.monthlyCost.toFixed(2))
      const color = CHART_COLORS[ctr]
      if (ctr === CHART_COLORS.length - 1) ctr = 0
      else ctr++
      return { name, value, color }
    })
  } return []
}

export function getProviderNames(receivedData: newTFocusGroup[]) {
  const res: string[] = ['']
  if (receivedData.length !== 0) {
    const labels = Object.getOwnPropertyNames(receivedData[0])
    labels.forEach((label) => {
      const { resources } = receivedData[0][label]
      resources.forEach((resource) => {
        const { provider } = resource
        const index = res.findIndex((el) => el === provider)
        if (index === -1) res.push(provider)
      })
    })
  }
  return res
}

function legendOver(name: string, chartValue: string, parentKey: string) {
  const id = `${parentKey}${name}`
  const element = document.getElementById(id)!;
  const newstr = name.concat(' : ').concat(chartValue)
  element.innerHTML = newstr
}
function legendOut(name: string, str: string, chartValue: string, parentKey: string) {
  const id = `${parentKey}${name}`
  const element = document.getElementById(id)!;
  const newstr = str.concat(' : ').concat(chartValue)
  element.innerHTML = newstr
}

const splitter = (value: string) => {
  let temp = value
  if (value.length > 10) {
    temp = value.substr(0, 10).concat('...')
  } else {
    temp = value
  }
  return temp
};

export const renderLegend = (data: TPieChartData[], parentKey: string, currency: TCurrency) => {
  return (
    <ul style={{ overflow: 'auto', height: '250px', width: '250px' }}>
      {
        data.map((
          entry: {
            value: any;
            color: any;
            name: any
          },
          index: any
        ) => (
          <li
            style={{ display: 'list-item', color: `${entry.color}`, listStyleType: 'disc', listStylePosition: 'inside' }}
            key={`item-${index}`}
            id={`${parentKey}${entry.name}`}
            onMouseOver={() => {
              legendOver(entry.name, entry.value, parentKey)
            }}
            onFocus={() => {
              legendOver(entry.name, entry.value, parentKey)
            }}
            onMouseOut={() => {
              legendOut(entry.name, splitter(entry.name), entry.value, parentKey)
            }}
            onBlur={() => {
              legendOut(entry.name, splitter(entry.name), entry.value, parentKey)
            }}
          >
            {splitter(entry.name)} : {currency.symbol}{entry.value}
          </li>
        ))
      }
    </ul>
  );
}
export function renderLabel(val: any, currency: TCurrency, maxVal: number) {
  const RADIAN = Math.PI / 180;
  const radius = val.innerRadius + (val.outerRadius - val.innerRadius) * 0.5;
  const x = val.cx + radius * Math.cos(-val.midAngle * RADIAN);
  const y = val.cy + radius * Math.sin(-val.midAngle * RADIAN);
  if ((val.value / maxVal) * 100 > 15) {
    return <text x={x} y={y} fill="white" textAnchor={x > val.cx ? 'start' : 'end'} dominantBaseline="central">{currency.symbol}{val.value}</text>
  } return <text />
}
