import { useEffect, useMemo, useState } from 'react'
import { BsTable } from 'react-icons/bs'
import { FaQuestion } from 'react-icons/fa'
import { v4 as uuid } from 'uuid'
import { TCurrency } from 'services/cost.interface'
import { ButtonIcon, FetchStatus, GraphCard, GraphCardContent, SpinnerLoader, Table, TTableColumn } from 'components'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import useProject from 'hooks/useProject'
import { PROJECT_TABLE_COLUMNS } from 'pages/projects/constants'
import { useQuery } from 'react-query'
import { axios } from 'utils'
import { newTFocusGroup, TCostHistoryQueryResponse, TFocusGroup } from 'services/costhistory.interface'
import { TBarChartData } from 'services/chart.interface'
import { effectData } from 'pages/label-rules/mutation-new-label-rule-model/hierarchyHelperFunctions'

import { Button } from '@mantine/core'
import { Tooltip } from '@mui/material'
import { useResizeObserver } from '@mantine/hooks'
import { useTour } from '@reactour/tour'
// import { Bounce, toast } from 'react-toastify'
import { componentWithNumber, notAllowedComponent, selectedComponent, selectionComponent } from './aggregationData'
import TableInfoCard from './TableInfoCards'
import { query } from '../project-widget/widgetHelpFunctions'
import { makeNewQueryRequest, noDupsLabelList, TagComponent } from './helper'
import { tableDataPrepFunction } from './tableDataPrep'
import { tourConfig } from './tour'
import { dateFormatChanger } from '../project-labeled-data-widget/chartHelperFunctions'
import { WidgetData } from '../project-widget/widgetStatev2'
import { TutorialContainer } from '../project-tutorial'
import { getWidgetData, setWidgetData } from '../project-widget/widgetDataCaching'

interface TableWidgetProps {
  id: string
  currency: TCurrency
  labelRuleDetailsList: effectData[]
  fields: string[]
  editCallBack: () => void
  closeCallBack: () => void
  deleteWidget: () => void
  widgetObject: WidgetData
  setErrorMessage: (msg:string) => void
  deleteErrorMessage: () => void
}

export function TableWidget({ id = '', currency, labelRuleDetailsList, fields, editCallBack, closeCallBack, deleteWidget, widgetObject, setErrorMessage, deleteErrorMessage }: TableWidgetProps) {
  const {
    fetchProjectsStatus,
    // projectList,
    fetchProjects
  } = useProject()
  const [toTutorial, setTutorial] = useState<boolean>(false)
  const { setIsOpen, setSteps, setCurrentStep } = useTour()
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)

  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }
  // ***************************************************************************
  // Reset the options as these need to be updated regularly.
  // Add all the variables that are needed to update the widget regularly.
  // ***************************************************************************
  widgetObject.resetOptions(id)
  const formatOptions = useMemo(() => ({ currency }), [currency])
  const [dataProcessed, setDataProcessed] =
    useState<boolean>(widgetObject.getTableDataAggregate(id).length === 0)
  const [queryKey, setQueryKey] = useState<string>(`initial${id}`)
  const [resourceDetailsProcessed, setResourceDetailsProcessed] = useState<boolean>(true)
  const [resourcelayout, setResourcelayout] = useState<boolean>(false)
  const [path, setPath] = useState<string[]>(widgetObject.getTableDataPath(id))
  const [columns, setColumns] = useState<TTableColumn[]>(PROJECT_TABLE_COLUMNS)
  const [resourceData, setResourseData] = useState<TFocusGroup[]>([])
  const [dataLoaded, setDataLoaded] = useState<boolean>(
    widgetObject.getTableDataAggregate(id).length === 0
  )
  const [resourceDataParsed, setResourceDataParsed] = useState<TBarChartData[]>([])
  const [editBool, setEditBool] = useState<boolean>(false)
  const [aggregateString, setAggregateString] = useState<string[]>(
    widgetObject.getTableDataAggregate(id)
  )
  const [monthlyCost, setMonthlyCost] = useState<number>(0)

  const [labelRuleDetailsTagList, setLabelRuleDetailsTagList] =
    useState<TagComponent[]>([])

  const [data, setData] = useState<newTFocusGroup[]>(
    getWidgetData(`TableWidget${widgetObject.getTableDataAggregate(id)}`) as newTFocusGroup[]
  )
  const [sessionStorageTag, setSessionStorageTag] = useState<string>(
    `TableWidget${widgetObject.getTableDataAggregate(id)}`
  )
  const [tagSelector, setTagSelector] = useState<string>(widgetObject.getTableDataSelector(id))
  const changeEditBool = () => {
    setEditBool(true)
  }
  const [ref, rect] = useResizeObserver();

  const reloadFunctionality = () => {
    setDataProcessed(false)
    setQueryKey(uuid())
  }
  useEffect(() => {
    if (fetchProjectsStatus.isLoading) reloadFunctionality()
  }, [fetchProjectsStatus])

  const [filterValue, setFilterValue] = useState<string>('')
  // ***************************************************************************
  // Add the Edit widget to the options of this widget.
  // ***************************************************************************
  widgetObject.editOptions(id, 'Edit widget', changeEditBool)

  widgetObject.editOptions(id, 'Reload widget', () => {
    reloadFunctionality()
  })
  widgetObject.editOptions(id, 'Delete widget', () => {
    sessionStorage.removeItem(sessionStorageTag)
    deleteWidget()
  })

  // ***************************************************************************
  // All that is needed to create a query and make Api calls is placed in this
  // section.
  // ***************************************************************************

  const [queryRequest, setQueryRequest] =
    useState<query>(makeNewQueryRequest(tagSelector, aggregateString))

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!(queryKey === `initial${id}` && data.length !== 0) && !dataProcessed && aggregateString.length !== 0) {
          await axios
            .post('/query', queryRequest)
            .then((res) => {
              setData(res.data.data)
              // console.log(res.data.data)
              setWidgetData(sessionStorageTag, `TableWidget${aggregateString}`, res.data.data)
              setSessionStorageTag(`TableWidget${aggregateString}`)
              setDataProcessed(true)
              deleteErrorMessage()
              console.log('success')
            })
            .catch((error) => {
              if (error.response.data.data) {
                setErrorMessage('There is no data to be found for this configuration.')
                setData([])
                setDataProcessed(true)
              }
            })
        } else if ((queryKey === `initial${id}` && data.length !== 0)) {
          setDataProcessed(true)
        }
        if (!dataProcessed && aggregateString.length !== 0) {
          setDataProcessed(true)
        }
        if (!resourceDetailsProcessed) {
          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setResourceDetailsProcessed(true)
              setResourseData(res.data.data)
            })
            .catch(() => {
              setResourceDetailsProcessed(true)
              setResourseData([])
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  const returnToMainView = () => {
    setEditBool(false)
  }

  // ==================================
  // Generate the tableData
  // ==================================

  const tableData = useMemo(() => {
    return tableDataPrepFunction(
      id,
      data,
      path,
      () => {
        if (toTutorial) {
          if (setSteps) {
            setSteps(
              tourConfig(
                id,
                [
                  editCallBack,
                  changeEditBool,
                  closeCallBack,
                  returnToMainView,
                  changeTutorialUpdater
                ]
              )
            )
          }
          setIsOpen(true)
          setTutorial(false)
          setCurrentStep(0)
        }
      },
      setMonthlyCost,
      setColumns,
      setResourceDetailsProcessed,
      setResourcelayout,
      setQueryKey,
      setQueryRequest,
      currency
    )
  }, [data, path])

  // ***************************************************************************
  // These are checks for when we are selecting tags, if you are a part of the
  // you have to be able to be deleted from it.
  // A tag who's not in the span and none of it's parents are the last of the span
  // (if there are parents)can not ever be selected until the aggregate is empty
  // again or a parent is added.
  // ***************************************************************************

  function isPartOfSpan(aggregate: string[], el: string) {
    const temp = aggregate.findIndex((piece) => { return piece === el })
    return temp !== -1
  }
  function parentInSpan(el: string, aggregate: string[]) {
    if (aggregate.length === 0) return true
    const temp = labelRuleDetailsList.filter((Obj) => Obj.name === el)
    const aggregatePieces = aggregate
    const lastParent = labelRuleDetailsList.find((el) => {
      return el.name === aggregatePieces[aggregatePieces.length - 1]
    })
    let res = false
    temp.forEach((Obj) => {
      if (lastParent !== undefined) {
        if (Obj.parentId === lastParent?.id) res = true
      }
    })
    return res
  }
  // ***************************************************************************
  // This remakes the list of tags, so that all know if they can be added or not.
  // ***************************************************************************
  function remakeList(
    aggregate: string[],
    list: { tag: string, selected: boolean, parentConditionMet: boolean }[]
  ) {
    const temp = list.map((el) => {
      if (tagSelector === 'labels') {
        return {
          tag: el.tag,
          selected: isPartOfSpan(aggregate, el.tag),
          parentConditionMet: parentInSpan(el.tag, aggregate)
        }
      }
      return {
        tag: el.tag,
        selected: isPartOfSpan(aggregate, el.tag),
        parentConditionMet: true
      }
    })
    temp.sort((a, b) => a.tag.localeCompare(b.tag))
    setLabelRuleDetailsTagList(temp)
  }

  useEffect(() => {
    let temp = []
    if (tagSelector === 'fields') {
      temp = fields.map((el) => {
        return { tag: el, selected: false, parentConditionMet: true }
      })
    } else {
      temp = noDupsLabelList(labelRuleDetailsList).map((el) => {
        return { tag: el, selected: false, parentConditionMet: true }
      })
    }
    remakeList(aggregateString, temp)
  }, [labelRuleDetailsList, fields])

  // ***************************************************************************
  // If we get to the resources of the data, we can display the data in a chart.
  // ***************************************************************************
  function newRDetails(name: string, value: number): TBarChartData {
    return { name, value, color: '#8884d8' }
  }
  useEffect(() => {
    const tempData: TBarChartData[] = []
    if (resourceData.length !== 0) {
      const pathLength = path.length
      const resourceId = path[pathLength - 1]
      resourceData.forEach((el) => {
        if (el[resourceId] !== undefined) {
          const date: Date = el[resourceId].timestamp
          const cost = Number(el[resourceId].totalBilledCost)
          const newDetails = newRDetails(dateFormatChanger(date.toString()), cost)
          tempData.push(newDetails)
        }
      })
      setResourceDataParsed(tempData)
      setDataLoaded(true)
    }
  }, [resourceData])

  useEffect(() => {
    if (resourceDataParsed.length !== 0 && !dataLoaded) {
      setDataLoaded(true)
    }
  }, [resourceDataParsed])

  function visualiseComponent(el: { tag: string, selected: boolean, parentConditionMet: boolean }) {
    if (el.selected) {
      return selectedComponent(el.tag)
    }
    if (!el.selected && el.parentConditionMet) {
      return selectionComponent(el.tag)
    }
    return notAllowedComponent(el.tag)
  }

  function up(tag: string) {
    const index = aggregateString.findIndex((el) => {
      return el === tag
    })
    aggregateString.splice(index - 1, 0, aggregateString.splice(index, 1)[0])
    remakeList(aggregateString, labelRuleDetailsTagList)
  }

  function down(tag: string) {
    const index = aggregateString.findIndex((el) => {
      return el === tag
    })
    aggregateString.splice(index + 1, 0, aggregateString.splice(index, 1)[0])
    remakeList(aggregateString, labelRuleDetailsTagList)
  }
  // ***************************************************************************
  // The render of the widget
  // ***************************************************************************
  if (error) return <div>An error has occurred</div>
  return (
    <div key={`ATableWidgetWithId${id}`} data-tour={`ATableWidgetWithId${id}`} ref={ref} className="h-full">

      <TutorialContainer
        tag="AggregationWidget"
        updater={tutorialUpdater}
        tourConfig={
          tourConfig(
            id,
            [
              editCallBack,
              changeEditBool,
              closeCallBack,
              returnToMainView,
              changeTutorialUpdater
            ]
          )
        }
      >
        <div className="block" style={{ height: `${rect.height.toFixed(1)}px` }}>
          <SpinnerLoader isLoading={!dataProcessed}>
            {editBool && (
              // ***************************************************************************
              // This is all the code for the edit part of the widget:
              // There is  a span with the tooltip explaining the widget
              // Then there are the 2 main components
              // One with the tabs that allow the user to select which tags are displayed,
              // in that same one there is also a search bar and the list of tags.
              // the other one shows all selected tags.
              // the last part are 2 buttons to save or cancel.
              // ***************************************************************************
              <div className="flex flex-col @container justify-items-stretch h-full overflow-auto">
                <div className="flex flex-row h-fit">
                  <span
                    key={`spanForTheEditingScreenForTableWidget${id}`}
                    className="text-center text-[#668957] font-bold text-lg span w-full"
                  >
                    Select the tags you want to aggregate your data with.
                    <Tooltip
                      className="opacity-50"
                      title="Here you can select the tags that are used by the api to fetch your data for the table widget.
                          Choose the order of these tags to make sure the data is displayed in the order you want to view it in."
                    >
                      <ButtonIcon>
                        <FaQuestion color="#668957" size={15} />
                      </ButtonIcon>
                    </Tooltip>
                  </span>

                </div>
                <div className="flex flex-col h-full w-full overflow-auto">
                  <div className="flex gap-1 w-full h-fit">
                    <div className="w-1/2">
                      <button
                        className={`border-2 px-1 h-8 transition-colors ease-in-out w-1/2 rounded-t-lg
                  ${tagSelector === 'fields' ? 'bg-[#668957] text-white border-[#668957]' : 'bg-white border-white'}`}
                        type="button"
                        onClick={() => {
                          const temp = fields.map((el) => {
                            return { tag: el, selected: false, parentConditionMet: true }
                          })
                          setTagSelector('fields')
                          setFilterValue('')
                          setAggregateString([])
                          remakeList([], temp)
                        }}
                      >
                        fields
                      </button>

                      <button
                        className={`border-2  px-1 h-8 transition-colors ease-in-out w-1/2 rounded-t-lg
                  ${tagSelector === 'labels' ? 'bg-[#668957] text-white border-[#668957]' : 'bg-white border-white'}`}
                        type="button"
                        onClick={() => {
                          const temp = noDupsLabelList(labelRuleDetailsList).map((el) => {
                            return { tag: el, selected: false, parentConditionMet: true }
                          })
                          setTagSelector('labels')
                          setFilterValue('')
                          setAggregateString([])
                          remakeList([], temp)
                        }}
                      >
                        labels
                      </button>
                    </div>
                    {/* just needed to make the tabs display correctly
                   however if we ever need tabs or something that looks similar,
                   then that should be placed in following div called Tab placeholder${id} */}
                    <div id={`placeholder${id}`} className="w-1/2" />
                  </div>

                  <div className="flex gap-1 h-full w-full overflow-auto">
                    <div
                      className="border-2 border-[#668957] w-1/2 h-full overflow-y-auto rounded-b-lg"
                    >
                      <div className="sticky border-b-2 border-[#668957] w-full">
                        <input
                          className="w-full h-8"
                          placeholder="Search a tag"
                          autoComplete="off"
                          onChange={(event) => {
                            setFilterValue(event.target.value)
                          }}
                        />
                      </div>
                      <div>
                        {labelRuleDetailsTagList.filter((el) => {
                          const parts = el.tag.split(' ')
                          const index = parts.findIndex((part) => {
                            return part.toLowerCase() === 'cost'
                          })
                          const filtervalueComparison = filterValue === '' ? true : el.tag.toLowerCase().includes(filterValue.toLowerCase())
                          return (
                            index === -1
                            && filtervalueComparison
                            && el.tag !== 'Updated flag'
                            && el.tag !== 'Data date'
                          )
                        }).map((el) => {
                          return (
                            <div
                              key={`aTagWithLabel${el.tag}ForTableWidget${id}`}
                              className="py-1 px-1 max-w-full"
                              onClick={() => {
                                let newAggregate: string[] = []
                                if (el.selected) {
                                  const index =
                                    aggregateString.findIndex((piece) => el.tag === piece)
                                  const temp1 = aggregateString.slice(0, index)
                                  const temp2 = aggregateString.slice(index + 1)
                                  newAggregate = temp1.concat(temp2)
                                  setAggregateString(newAggregate)
                                } else if (tagSelector === 'fields' || el.parentConditionMet) {
                                  newAggregate = aggregateString.concat(el.tag)
                                  setAggregateString(newAggregate)
                                } else newAggregate = aggregateString
                                remakeList(newAggregate, labelRuleDetailsTagList)
                              }}
                            >
                              {visualiseComponent(el)}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="border-2 border-[#668957] w-1/2 h-full overflow-y-auto rounded-lg justify-start content-start">
                      {aggregateString.map((el, idx) => {
                        const maxNumber = aggregateString.length - 1
                        if (el === '') return <div />
                        return (
                          <div
                            key={`aggrgateStringPartFor${id}WithValue${el}`}
                            className="py-1 px-1 max-w-full"
                          >
                            {componentWithNumber(
                              el,
                              idx,
                              maxNumber,
                              up,
                              down,
                              () => {
                                let newAggregate: string[] = []
                                if (aggregateString[0] === el) {
                                  if (aggregateString.length === 1) newAggregate = []
                                  else newAggregate = aggregateString.slice(1)
                                } else {
                                  const index =
                                    aggregateString.findIndex((piece) => { return el === piece })
                                  const temp1 = aggregateString.slice(0, index)
                                  const temp2 = aggregateString.slice(index + 1)
                                  newAggregate = temp1.concat(temp2)
                                }
                                setAggregateString(newAggregate)
                                remakeList(newAggregate, labelRuleDetailsTagList)
                              }
                            )}
                          </div>
                        )
                      })}
                    </div>

                  </div>
                </div>

                <div className="justify-self-center py-4 flex place-content-center">
                  <div className="px-3">
                    <Button
                      className="bg-white hover:bg-gray-600 text-black hover:text-white border-2 border-black"
                      onClick={() => {
                        // setPath(getTableDataPath(id))
                        setAggregateString(widgetObject.getTableDataAggregate(id))
                        setEditBool(false)
                        setFilterValue('')
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="px-3">
                    <Button
                      className="bg-[#668957] hover:bg-[#93ac89] border-2 border-[#668957]"
                      onClick={() => {
                        if (aggregateString.length !== 0) {
                          const newQuery = makeNewQueryRequest(tagSelector, aggregateString)
                          setQueryRequest(newQuery)
                          setEditBool(false)
                          setDataProcessed(false)
                          setQueryKey(uuid())
                          widgetObject.setTableDataAfterNewCall(id, aggregateString, tagSelector)
                          widgetObject.setTableDataWhenWalkingThroughData(id, [])
                          setFilterValue('')
                          setPath([])
                          setResourseData([])
                          setResourceDataParsed([])
                          setResourcelayout(false)
                        } else {
                          // const newQuery = makeNewQueryRequest()
                          // setQueryRequest(newQuery)
                          setEditBool(false)
                          // setDataProcessed(false)
                          // setQueryKey(uuid())
                          setData([])
                          setColumns(PROJECT_TABLE_COLUMNS)
                          setMonthlyCost(0)
                          setResourcelayout(false)
                          widgetObject.setTableDataAfterNewCall(id, aggregateString, tagSelector)
                          widgetObject.setTableDataWhenWalkingThroughData(id, [])
                          setFilterValue('')
                          setPath([])
                          setResourseData([])
                          setResourceDataParsed([])
                          setResourcelayout(false)
                        }
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {!editBool && (
              // ***************************************************************************
              // This is all the code for the display of data
              // We have a path that can be clicked to go back
              // The table cards that show some more information about the table
              // The table itself follows
              // When we get to the last part of the data ie. a resource it will also show a chart
              // This is the last part of this side of the widget.
              // ***************************************************************************
              <div className="h-full">
                <BsTable size={20} />
                <div
                  className="flex items-center w-full text-wrap"
                >
                  <span key={`spanForTheTableScreenForTableWidget${id}`} data-tour={`spanForTheTableScreenForTableWidget${id}`} className="text-wrap">
                    {([''].concat(path).map((el) => {
                      return (
                        <button
                          type="button"
                          key={`thePathFor${id}${el}`}
                          className="text-sm text-gray-500 font-semibold px-0 cursor-pointer hover:underline text-wrap"
                          onClick={() => {
                            setResourcelayout(false)
                            if (el === '') {
                              widgetObject.setTableDataWhenWalkingThroughData(id, [])
                              setPath([])
                              setResourceDataParsed([])
                              setResourseData([])
                              setQueryKey(`initial${id}`)
                            } else {
                              const index = path.findIndex((pathPiece) => {
                                return pathPiece === el
                              })
                              const newPath = path.slice(0, index + 1)
                              if (newPath !== path) {
                                setResourceDataParsed([])
                                setResourseData([])
                                setQueryKey(`initial${id}`)
                              }
                              widgetObject.setTableDataWhenWalkingThroughData(id, newPath)
                              setPath(newPath)
                            }
                          }}
                        >
                          {`${el === '' ? 'Home' : `/${el}`}`}
                        </button>
                      )
                    }))}
                  </span>
                </div>
                <div className="block w-full h-fit">
                  <TableInfoCard
                    currency={currency}
                    listOfChosentags={aggregateString}
                    // currentTag={path.length - 1}
                    cost={monthlyCost}
                    path={path}
                    id={id}
                  />
                </div>
                <div className={`${rect.width > 850 ? 'flex flex-row' : 'block'}`}>
                  <div data-tour={`TheTableElementOf${id}`} className={`${rect.width > 850 && resourcelayout ? 'w-1/2' : 'w-full'}`}>
                    <GraphCardContent minHeight={250} className=" py-2 h-full">
                      <FetchStatus status={fetchProjectsStatus} retry={reloadFunctionality}>
                        <Table
                          columns={columns}
                          data={tableData}
                          formatOptions={formatOptions}
                          onRowClick={(row) => {
                            if (row.path !== undefined) {
                              const newPath = row.path.concat(row.name)
                              widgetObject.setTableDataWhenWalkingThroughData(id, newPath)
                              setPath(newPath)
                            }
                          }}
                        />
                      </FetchStatus>
                    </GraphCardContent>
                  </div>
                  {resourcelayout && (
                    <SpinnerLoader isLoading={!resourceDetailsProcessed}>
                      <GraphCard isOutline={false}>
                        <span key={`spanForTheResourceChartForTableWidget${id}`} className="text-lg font-medium text-gray-800 w-full">
                          Cost of last month
                        </span>
                        <GraphCardContent>
                          <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
                            {/* <BarChartComponent data={resourceDataParsed}
                          currency={currency} /> */}
                            <div className="w-full h-full">
                              <ResponsiveContainer>
                                <BarChart
                                  data={resourceDataParsed}
                                >
                                  <YAxis dataKey="value" tickFormatter={(value) => `${currency?.symbol || ''}${value}`} />
                                  {/* <Tooltip children={undefined} title={undefined} /> */}
                                  <Bar dataKey="value" fill="#8884d8" />
                                  <XAxis dataKey="name" angle={45} />
                                </BarChart>
                              </ResponsiveContainer>
                            </div>
                          </FetchStatus>
                        </GraphCardContent>
                      </GraphCard>
                    </SpinnerLoader>
                  )}
                </div>
              </div>
            )}
          </SpinnerLoader>
        </div>
      </TutorialContainer>
    </div>
  )
}
